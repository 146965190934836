import { gql } from '@apollo/client';

export const GET_ALL_PRODUCTS = gql`
  query getAllProducts($companyId: Int, $search: String, $limit: Int, $offset: Int, $status: String) {
    productListQuery(companyId: $companyId, search: $search, limit: $limit, offset: $offset, status:$status) {
      limit
      offset
      totalPages
      totalRecords
      rows {
        id
        name {
          ar
          en
        }
        description {
          ar
          en
        }
        type
        price
        image
        posId
        company{
          name {
            ar
            en
          }
        }
        status
        defaultSalesPrice
      }
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query adminProductDetailsQuery($id: Int!) {
    adminProductDetailsQuery(id: $id) {
      id
      companyId
      categoryId
      type
      posId
      isRecommend
      sortOrder
      services
      chefPick
      productModGroups{
       id
       quantity
       modGroupType
       sortOrder
      modGroupName{en}
      modLabel
      }

       
      chefPickImages{
       home{
        web
        mobile
       }
       category{
        web
        mobile
       }
      }
      marketPrice
      default
      name {
        ar
        en
      }
      description {
        ar
        en
      }
      price
      marketPrice
      defaultSalesPrice
      preOrderingTiming
      image
      availability{
        date{
          start
          end
          option
        }
        day{
          days
          option
        }
        time{
          start
          end
          option
        }
      }
      timed
    }
  }
`
export const GET_COMPANY_STORE_PRODUCTS = gql`
  query companyStoreProductsQuery($companyId: Int!, $productId: Int, $search: String, $limit: Int, $offset: Int) {
    companyStoreProductsQuery(companyId: $companyId, productId: $productId, search: $search, limit: $limit, offset: $offset) {
      rows {
        id
        name {
          ar
          en
        }
        photo
        address{
        city{
          en
          }
        }
        products {
          productId
          isAvailable
          prices {
            DELIVERY
            TAKEAWAY
            GLOBAL_TAKEAWAY
            GLOBAL_DELIVERY
            DINE_IN
          }
        }
      }
    }
  }
`
