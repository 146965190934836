import React, { useEffect, useState } from 'react'
import { GET_MODIFIER_GROUP } from '@common/src/lib/graphql/query/store'
import { useQuery } from '@apollo/client'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import ModifierGroupAdd from './ModiferGroupAdd'
import AddProductButton from '@hub/components/common/buttons/AddProductButton'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { handleAsyncBackOperation } from '../../helper'

const Modifiers = ({ setProductData, productData, handleNext, handleBack, isEdit, editProductData, setEditProductData, setDisableNavigation}) => {
  const { selectedCompany } = useSelector((state) => state.auth);
  const [modifiersData, setModifiersData] = useState([{
    modifierGroup: '',
    quantity: '',
  }])

  const [modifierGroupData, setModifierGroupData] = useState([])
  const [tableData, setTableData] = useState(productData?.productModGroups || [])
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isBackLoading, setIsBackLoading] = useState(false);
  const { isVisible, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()

  const [pagination] = useState({
    limit: 50,
    offset: 0,
    currentPage: 1,
  });

  const { data, refetch } = useQuery(GET_MODIFIER_GROUP, {
    variables: {
      companyId: parseInt(selectedCompany?.value),
      limit: pagination.limit,
      offset: pagination.offset,
      search: ""
    },
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      setModifiersData(data.modifierGroupQuery.rows.map(item => ({
        modifierGroup: item.modGroupName?.en || '',
        quantity: '',
      })))

      setModifierGroupData(data.modifierGroupQuery.rows.map(item => ({
        value: item.id,
        label: item.modGroupName?.en + (item.modLabel ? ` (${item.modLabel})` : '') || '',
        modGroupType: item.modGroupType,

      })))

      // Optionally update tableData if needed
      setTableData(productData?.productModGroups || [])
    }
  }, [data]);

  useEffect(() => {
    refetch({
      variables: {
        companyId:parseInt(selectedCompany?.value),
        limit: pagination.limit,
        offset: pagination.offset,
        search: ""
      },
    });
  }, [pagination.offset]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setDisableNavigation(true)
  
    try {
      const filteredTableData = tableData.filter(item => item.id !== null);
      const sortedTableData = filteredTableData.map((item, index) => ({
        ...item,
        sortOrder: index + 1
      }));
  
      if (isEdit) {
        const cleanedData = sortedTableData.map((item) => (
          item.__typename ? {
            modGroupId: item.id,
            modGroupName: item.modGroupName.en || item.modGroupName,
            quantity: item.quantity,
            sortOrder: item.sortOrder
          } : item
        ));
        setEditProductData((prevData) => ({
          ...prevData,
          productModGroups: cleanedData,
          customizable: sortedTableData.length > 0
        }));
        setProductData({ ...productData, productModGroups: cleanedData });
      } else {
        setProductData({
          ...productData,
          productModGroups: sortedTableData,
          customizable: sortedTableData.length > 0,
        });
      }
  
      await new Promise(resolve => setTimeout(resolve, 1000));
      handleNext();
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsLoading(false);
    }
    setDisableNavigation(false)
  };

  const handleBackWithLoader = async () => {
   handleAsyncBackOperation(handleBack, setIsBackLoading, setDisableNavigation);
  };
  

  const onClose = () => {
    closeOffCanvas(false)
  }

  const handleEditModifier = (modifier) => {
    console.log('modifier', modifiersData);
    setEditData(modifier);
    openOffCanvas('editModifier', 'Edit Modifier');
  };  
  const handleDeleteModifier = (modifierId) => {
    if(modifierId){
      setTableData((prevData) => prevData.filter((item) => item.modGroupId !== modifierId));
    }
};
  

  const handleAddModifier = () => {
    setEditData(null); // Reset edit data
    openOffCanvas('addModifier', 'Add Modifier'); // Open the form in add mode
    };
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="flex">
            <button
              type="button"
              onClick={handleAddModifier}
              className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary"
            >
              <i className={`ri-add-line font-semibold align-middle me-1`}></i>Add Modifier Group
            </button>
          </div>
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <div className="box-body">
                <div className="table-responsive mb-4">
                  <table className="table whitespace-nowrap table-bordered min-w-full">
                    <thead>
                      <tr>
                        <th scope="col" className="text-start">
                          Modifier Group
                        </th>
                        <th scope="col" className="text-start">
                          Quantity
                        </th>
                        <th scope="col" className="text-start">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((modifier, index) => (
                        <tr className="product-list" key={modifier?.id}>
                          <td>{modifier?.modGroupName?.en || modifier?.modGroupName}
                            {modifier?.modLabel ? ` (${modifier?.modLabel})` : ''}
                          </td>
                          <td>{modifier.quantity || '-'}</td>
                          <td>
                            <div className="space-x-1 rtl:space-x-reverse">
                              <button
                                type="button"
                                onClick={() => handleEditModifier(modifier)}
                                className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
                              >
                                <i className="ri-pencil-line"></i>
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteModifier(modifier?.modGroupId)}
                                className="ti-btn ti-btn-danger ti-btn-icon ml-5 ms-1 ti-btn-sm invoice-btn"
                              >
                                <i class="ri-delete-bin-5-line"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <AddProductButton
                    handleBack={handleBackWithLoader}
                    handleNext={handleSubmit}
                    loadingBack={isBackLoading}
                    loadingNext={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FloatingCanvas onClose={onClose} isVisible={isVisible} close={closeOffCanvas} title={offCanvasTitle}>
        <ModifierGroupAdd
          options={modifierGroupData}
          setTableData={setTableData}
          tableData={tableData}
          data={editData}
          isEdit={!!editData}
          onClose={onClose}
        />
      </FloatingCanvas>
    </div>
  )
}

Modifiers.propTypes = {
  setProductData: PropTypes.func,
  productData: PropTypes.object,
  handleNext: PropTypes.func,
  isEdit: PropTypes.bool,
  editProductData: PropTypes.object,
  setEditProductData: PropTypes.func,
  handleBack: PropTypes.func,
  setDisableNavigation: PropTypes.func
}

export default Modifiers

